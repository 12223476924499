import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

const styles = theme => ({
  spacer: { margin: theme.spacing.unit * 4 },
  socialMedia: { margin: theme.spacing.unit },
})

function social(props) {
  const { classes } = props

  return (
    <Fragment>
      {/* <Divider className={classes.spacer} /> */}
      <Typography variant="subtitle1" align="center" gutterBottom>
        Visit us on Twitter and Facebook
      </Typography>
      <center>
        <a
          href="https://twitter.com/DropDeadFunny2?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classes.socialMedia}
            alt="twitter"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCA1MCA1MCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48ZyBpZD0ic3VyZmFjZTEiPjxwYXRoIHN0eWxlPSIgIiBkPSJNIDUwLjA2MjUgMTAuNDM3NSBDIDQ4LjIxNDg0NCAxMS4yNTc4MTMgNDYuMjM0Mzc1IDExLjgwODU5NCA0NC4xNTIzNDQgMTIuMDU4NTk0IEMgNDYuMjc3MzQ0IDEwLjc4NTE1NiA0Ny45MTAxNTYgOC43Njk1MzEgNDguNjc1NzgxIDYuMzcxMDk0IEMgNDYuNjkxNDA2IDcuNTQ2ODc1IDQ0LjQ4NDM3NSA4LjQwMjM0NCA0Mi4xNDQ1MzEgOC44NjMyODEgQyA0MC4yNjk1MzEgNi44NjMyODEgMzcuNTk3NjU2IDUuNjE3MTg4IDM0LjY0MDYyNSA1LjYxNzE4OCBDIDI4Ljk2MDkzOCA1LjYxNzE4OCAyNC4zNTU0NjkgMTAuMjE4NzUgMjQuMzU1NDY5IDE1Ljg5ODQzOCBDIDI0LjM1NTQ2OSAxNi43MDMxMjUgMjQuNDQ5MjE5IDE3LjQ4ODI4MSAyNC42MjUgMTguMjQyMTg4IEMgMTYuMDc4MTI1IDE3LjgxMjUgOC41MDM5MDYgMTMuNzE4NzUgMy40Mjk2ODggNy40OTYwOTQgQyAyLjU0Mjk2OSA5LjAxOTUzMSAyLjAzOTA2MyAxMC43ODUxNTYgMi4wMzkwNjMgMTIuNjY3OTY5IEMgMi4wMzkwNjMgMTYuMjM0Mzc1IDMuODUxNTYzIDE5LjM4MjgxMyA2LjYxMzI4MSAyMS4yMzA0NjkgQyA0LjkyNTc4MSAyMS4xNzU3ODEgMy4zMzk4NDQgMjAuNzEwOTM4IDEuOTUzMTI1IDE5Ljk0MTQwNiBDIDEuOTUzMTI1IDE5Ljk4NDM3NSAxLjk1MzEyNSAyMC4wMjczNDQgMS45NTMxMjUgMjAuMDcwMzEzIEMgMS45NTMxMjUgMjUuMDU0Njg4IDUuNSAyOS4yMDcwMzEgMTAuMTk5MjE5IDMwLjE1NjI1IEMgOS4zMzk4NDQgMzAuMzkwNjI1IDguNDI5Njg4IDMwLjUxNTYyNSA3LjQ5MjE4OCAzMC41MTU2MjUgQyA2LjgyODEyNSAzMC41MTU2MjUgNi4xODM1OTQgMzAuNDUzMTI1IDUuNTU0Njg4IDMwLjMyODEyNSBDIDYuODY3MTg4IDM0LjQxMDE1NiAxMC42NjQwNjMgMzcuMzkwNjI1IDE1LjE2MDE1NiAzNy40NzI2NTYgQyAxMS42NDQ1MzEgNDAuMjMwNDY5IDcuMjEwOTM4IDQxLjg3MTA5NCAyLjM5MDYyNSA0MS44NzEwOTQgQyAxLjU1ODU5NCA0MS44NzEwOTQgMC43NDIxODggNDEuODI0MjE5IC0wLjA1ODU5MzggNDEuNzI2NTYzIEMgNC40ODgyODEgNDQuNjQ4NDM4IDkuODk0NTMxIDQ2LjM0NzY1NiAxNS43MDMxMjUgNDYuMzQ3NjU2IEMgMzQuNjE3MTg4IDQ2LjM0NzY1NiA0NC45NjA5MzggMzAuNjc5Njg4IDQ0Ljk2MDkzOCAxNy4wOTM3NSBDIDQ0Ljk2MDkzOCAxNi42NDg0MzggNDQuOTQ5MjE5IDE2LjE5OTIxOSA0NC45MzM1OTQgMTUuNzYxNzE5IEMgNDYuOTQxNDA2IDE0LjMxMjUgNDguNjgzNTk0IDEyLjUgNTAuMDYyNSAxMC40Mzc1IFogIj48L3BhdGg+PC9nPjwvc3ZnPg=="
          />
        </a>

        <a
          href="https://www.facebook.com/Drop-Dead-Funny-3134708943221852/?modal=admin_todo_tour"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={classes.socialMedia}
            alt="facebook"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNTAiIGhlaWdodD0iNTAiCnZpZXdCb3g9IjAgMCA1MCA1MCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij4gICAgPHBhdGggZD0iTTQxLDRIOUM2LjI0LDQsNCw2LjI0LDQsOXYzMmMwLDIuNzYsMi4yNCw1LDUsNWgzMmMyLjc2LDAsNS0yLjI0LDUtNVY5QzQ2LDYuMjQsNDMuNzYsNCw0MSw0eiBNMzcsMTloLTJjLTIuMTQsMC0zLDAuNS0zLDIgdjNoNWwtMSw1aC00djE1aC01VjI5aC00di01aDR2LTNjMC00LDItNyw2LTdjMi45LDAsNCwxLDQsMVYxOXoiPjwvcGF0aD48L3N2Zz4="
          />
        </a>
      </center>
    </Fragment>
  )
}

social.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(social)
