import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  form: { paddingRight: theme.spacing.unit * 2 },
})

function contact(props) {
  const { classes } = props

  return (
    <Fragment>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success"
        className={classes.form}
      >
        <input type="hidden" name="bot-field" />

        <input type="hidden" name="form-name" value="contact" />
        <TextField
          id="name"
          label="Name"
          type="text"
          name="name"
          className={classes.textField}
          margin="normal"
        />
        <TextField
          required
          id="email"
          label="email"
          type="email"
          name="email"
          className={classes.textField}
          margin="normal"
        />
        <TextField
          id="message"
          name="message"
          fullWidth
          placeholder="Message"
          multiline
          className={classes.textField}
          margin="normal"
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          className={classes.button}
        >
          Submit
        </Button>
      </form>
    </Fragment>
  )
}

contact.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(contact)
