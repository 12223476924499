import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Divider, withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/seo"
import Social from "../components/social"
import Contact from "../components/contact"
import Blurb from "../components/blurb"
import Players from "../components/players"

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: "960px",
    margin: "0 auto",
  },
  logo: {
    maxWidth: 350,
    margin: "0 auto",
  },
  button: {
    margin: theme.spacing.unit,
  },
  link: { textDecoration: "none" },
  margins: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
  },
  marginBorder: { margin: theme.spacing.unit * 2 },
  spacer: { margin: theme.spacing.unit * 4 },
  bodyMargin: {
    // marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
  },
})

function twentyNineteen(props) {
  const { classes } = props

  return (
    <div className={classes.bodyMargin}>
      <SEO
        title="Fundraiser"
        keywords={[
          `Deborah Jarvis`,
          `Deb Jarvis`,
          `Dropdeadfunny`,
          "John Candy Box Theatre",
        ]}
      />
      <Grid container className={classes.root} justify="center" spacing={16}>
        <Grid item xs={12}>
        <Link to="/">
        <Typography variant="h5" align="center" gutterBottom>
            Back to Drop Dead Funny 2020
          </Typography>
        </Link>
          <Img
            fluid={props.data.deborah.childImageSharp.fluid}
            className={classes.marginBorder}
          />
          {/* <Typography variant="h5" align="center" gutterBottom>
            The First Annual Deborah Jarvis Memorial Comedy Jam
          </Typography> */}
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            className={classes.margins}
          >
            "Drop Dead Funny; The Deborah Jarvis Memorial Comedy Jam" was a
            phenomenal success. A sold-out house enjoyed some of Canada's most
            talented comics giving their all in a worthy cause, the{" "}
            <b>
              <a href="https://www.oafdn.ca/donations/new" target="_blank">
                The Second City Deborah Jarvis Award for Canadian Comediennes
              </a>
            </b>
            .
          </Typography>
          <Typography
            variant="body1"
            // align="center"
            gutterBottom
            className={classes.margins}
          >
            70 people, friends, family and admirers of Deb, turned out on
            Sunday, June 23 (Deborah and her husband, John's, wedding
            anniversary) in the afternoon at the John Candy Box Theatre at
            Second City in downtown Toronto. They got 90 minutes of laughs,
            memories and great comedy.
          </Typography>

          <Players data={props.data} className={classes.marginBorder} />
        </Grid>

        {/* <Divider className={classes.spacer} />
        <Typography
          variant="subtitle1"
          align="center"
          gutterBottom
          className={classes.margins}
        >
          Here's a short clip of Deb Kimmett rehearsing with the cast before the
          show:
        </Typography> */}
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          className={classes.margins}
        >
          The tape of the complete show, all 90 minutes and suitable for home
          viewing, is available to those who make a donation to the{" "}
          <b>
            <a href="https://www.oafdn.ca/donations/new" target="_blank">
              The Second City Deborah Jarvis Award for Canadian Comediennes
            </a>
          </b>{" "}
          by clicking on this link.
        </Typography>

        <Grid item xs={12} md={8}>
          <Divider className={classes.margins} />
          <Typography variant="h5" align="center">
            Second City Deborah Jarvis Award for Canadian Comediennes
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            <p>
              In 2020, Second City will begin handing out the Second City
              Deborah Jarvis Award for Canadian Comediennes at an annual
              showcase, where the winner and other finalists will perform their
              work.
            </p>
            <p>
              This year’s inaugural showcase will be at the John Candy Box
              Theatre, Second City, 99 Blue Jays Way, Toronto on Saturday, June
              20, 2020, at 8:00 PM.
            </p>
          </Typography>
          <Divider className={classes.margins} />
        </Grid>

        <Grid item xs={12}>
          <Blurb />
        </Grid>

        <Grid item xs={12} md={6} align="center">
        <Link to="/">
        <Typography variant="h5" align="center" gutterBottom>
            Back to Drop Dead Funny 2020
          </Typography>
        </Link> 
        
          <a
            href="https://www.oafdn.ca/donations/new"
            target="_blank"
            className={classes.link}
          >
            <Button
              variant="contained"
              className={classes.button}
              size="large"
              fullWidth
            >
              Make a Donation Today
            </Button>
          </a>
        </Grid>

        <Grid item xs={12}>
          <Social />
        </Grid>
      </Grid>
    </div>
  )
}

twentyNineteen.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(twentyNineteen)

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 960) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    deborah: file(relativePath: { eq: "debImage2.png" }) {
      ...fluidImage
    }
    logo: file(relativePath: { eq: "JohnCandy2.png" }) {
      ...fluidImage
    }
    first: file(relativePath: { eq: "firstBW.jpg" }) {
      ...fluidImage
    }
    second: file(relativePath: { eq: "secondBW.jpg" }) {
      ...fluidImage
    }
    third: file(relativePath: { eq: "thirdBW.jpg" }) {
      ...fluidImage
    }
    fourth: file(relativePath: { eq: "fourthBW.jpg" }) {
      ...fluidImage
    }
    fifth: file(relativePath: { eq: "fifthBW.jpg" }) {
      ...fluidImage
    }
    sixth: file(relativePath: { eq: "sixthBW.jpg" }) {
      ...fluidImage
    }
    seventh: file(relativePath: { eq: "seventhBW.jpg" }) {
      ...fluidImage
    }
  }
`
