import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from "@material-ui/core"
// import { withStyles } from "@material-ui/core/styles"
// import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  root: {
    // width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  listItem: { marginTop: -10 },
})

const playersInfo = [
  {
    name: "Colin Mochrie",
    bio:
      "Long famous to Canadian TV audiences for his role on Whose Line is it Anyway, and This Hour Has 22 Minutes,  Colin worked with Deborah in the Second City Touring Company too many years ago to mention here.",
  },
  {
    name: "Deb Kimmett",
    bio:
      "With a background in Acme Harpoon Company with Deborah Jarvis, and later, The Two Debs with her, and the Second City Touring Company, Deborah is one of Deborah's oldest friends.",
  },
  {
    name: "Ed Sahely",
    bio:
      "Another alumnus of the Second City Touring Company, and a great friend of Deborah's.",
  },
  {
    name: "Lindsay Leese",
    bio:
      "An alumna of the Second City Touring Company, and also Acme Harpoon Company with Deborah.",
  },
  {
    name: "Tom Mellisis",
    bio:
      "Canadian TV audiences will recognize Tom from De Grassi, The Next Generation and De Grassi Junior High, and he is another alumnus of the Second City Touring Company.",
  },
  {
    name: "Mrs Pat Yeatman",
    bio:
      "A dear friend of Deb's, Mrs Pat is also an alumna of the Second City Touring Company and The Two Debs.",
  },
  {
    name: "Barb Muller",
    bio:
      "Barb is an alumna of the Second City Touring Company, and also worked with The Two Debs.",
  },
  {
    name: "Julia Bennett",
    bio:
      "A writer and producer, Julia was a member of the nascent comedy group Don't Worry Mom, back in the day.",
  },
  {
    name: "Barry Stevens",
    bio:
      "Barry is a documentary producer and writer, an alumnus of Acme Harpoon Company, and a founding member of Don't Worry Mom.",
  },
]

function players(props) {
  const { classes } = props
  return (
    <Fragment>
      <Typography variant="h5" align="center">
        Here's the line-up of the Deborah Jarvis Players:
      </Typography>
      <Grid container spacing={16} justify="center">
        <Grid item xs={12} md={10}>
          <List className={classes.root}>
            {playersInfo.map(player => {
              return (
                <ListItem alignItems="flex-start" className={classes.listItem}>
                  <ListItemText
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {player.name} :{" "}
                        </Typography>
                        {player.bio}
                      </>
                    }
                  />
                </ListItem>
              )
            })}
          </List>

          <Img
            fluid={props.data.second.childImageSharp.fluid}
            className={props.className}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.third.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            Lindsay Leese and Tom Melissis are getting catty with each other.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.fourth.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            Julia Bennett and Barry Stevens discuss "Artists With Animal Last
            Names".
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.seventh.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            Mrs Pat Yeatman, Ed Sahely, Barry Stevens and Julia Bennett enjoy
            the show from the cheap seats.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.first.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            Tom Melissis, Colin Mochrie and Lindsay Leese are rehearsing.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.fifth.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            Barb Muller, Lindsay Leese, Tom Melissis Mrs Pat Yeatman and Ed
            Sahely argue over the barbecue.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Img
            fluid={props.data.sixth.childImageSharp.fluid}
            className={props.className}
          />
          <Typography variant="body1" align="center">
            John Corbett chokes up addressing the audience.
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  )
}

players.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(players)
