import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = theme => ({})

function blurb(props) {
  return (
    <Fragment>
      <Typography variant="h5" align="center" gutterBottom>
        The Deborah Jarvis Fund for Canadian Comediennes
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        <p>
          Deborah Jarvis (1954 to 2019) was a gifted comic and a prolific
          writer. To honour her memory, her friends have founded{" "}
          <b>
            <a href="https://www.oafdn.ca/donations/new" target="_blank">
              The Second City Deborah Jarvis Award for Canadian Comediennes
            </a>
          </b>
          , an annual gift which will be awarded to a female comic at the
          beginning of her career. The Drop Dead Funny benefit will raise money
          for this fund.
        </p>
      </Typography>
    </Fragment>
  )
}

blurb.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(blurb)
